


































import { Vue, Component, VModel, Prop } from 'vue-property-decorator'
import { TabKey } from './types'

const sendGoal = (goal: string) => window.goal('Calendar', {
  Calendar: goal,
})

@Component
export default class MeetingDialogTabs extends Vue {
  @VModel({
    required: true,
    type: String,
  }) selected!: TabKey

  @Prop({
    type: Boolean,
  }) disable!: boolean

  private tabs: { key: TabKey, title: string }[] = [{
    key: 'all',
    title: this.$t('meetings.meetingDialog.tabs.all').toString(),
  }, {
    key: 'cell',
    title: this.$t('meetings.meetingDialog.tabs.cell').toString(),
  }, {
    key: 'next',
    title: this.$t('meetings.meetingDialog.tabs.next').toString(),
  }]

  private changeSelectedTab (val: TabKey): void {
    if (this.disable) return

    const goal = val === 'cell'
      ? 'Только эту встречу'
      : val === 'all' ? 'Все встречи' : 'Эту и последующие'
    sendGoal(`Окно редактирования — клик «${goal}»`)

    this.selected = val
  }
}
